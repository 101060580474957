<template>
    <front-layout>
        <v-container style="min-height: calc(100vh - 206px)">
            <h1 class="mt-10 main_color_text">Page not found!</h1>
            <h2 class="mt-3">Error 404</h2>
        </v-container>
    </front-layout>
</template>

<script>
    import FrontLayout from "../../layouts/FrontLayout";
    export default {
        components: {FrontLayout},
        data: function () {
            return {}
        },
    }
</script>

<style scoped lang="scss">

</style>
`
